<style>
.image{
  overflow:hidden;
  max-width:55px;
}
.card{
    display:flex;
    flex-direction: column;
    align-items: center;
    /* overflow:scroll; */
    justify-content:center;
    overflow:auto;
    width:35%;
    height:75%;
    background:#FFFFFF;
    position:absolute;
    /* margin-top:14%%; */
    top:13%;
    right: 7%;
    /* left: calc(); */
    border-radius:18px;
    box-shadow:0px 4px 4px rgba(0,0,0,0.5px);
}
.btn-login{
    border-radius:8px;
    /* padding-bottom:40px; */
    font-style: normal;
    text-transform: none;

}

.font-title{
    /* font-family: 'Source Sans Pro'; */
    display:flex;
    color:#3A4C40;
    font-weight:bold;
    line-height:30px;
    font-size:32px;
    justify-content:center;
    align-items:center;
}
.font-subtitle{
    /* font-family: 'Source Sans Pro'; */
    font-style: normal;
    font-weight: 600;
    font-size: 22 px;
    line-height: 32px;
    color:#3A4C40;

}
.font-text-1{
    /* font-family: 'Source Sans Pro'; */
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 24px;
    color:#FFFFFF
}
.div-input{
  padding-left:10px;
  padding-right:10px;
}
.input-field{
    box-sizing: border-box;
    background: rgba(245, 245, 247, 0.57);
    border: 1px solid #BABFC5;
    border-radius: 8px;
    height:60%;
    width:100%;
    font-size:18px;
}
.background::before {
  content: "";
  position: absolute;
  top: 0;

  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(269.91deg, rgba(0, 0, 0, 0.4) 0.1%, rgba(0, 0, 0, 0.289583) 69.07%, rgba(0, 0, 0, 0) 101.97%),url(/images/background.png);
  background-size: cover;
}


@media screen and (max-width:600px){
  .card{
    display:flex;
    flex-direction: column;
    align-items: center;
    /* overflow:scroll; */
    justify-content:center;
    overflow:auto;
    /* width:35%;
    height:75%; */
    background:#FFFFFF;
    position:absolute;
    /* margin-top:14%%; */
    /* left: calc(); */
    border-radius:18px;
    box-shadow:0px 4px 4px rgba(0,0,0,0.5px);
}
}
</style>

<template>
    <v-container class="background">
      <div class="d-flex flex-row">
        <div class="d-flex flex-column">
          <div class="d-flex flex-row">
            <v-card class="card" style="border-radius:15px">
              <div class="d-flex flex-column mb-0 pb-0 ">
                <div class="d-flex justify-center mt-4">
                  <v-img
                  src="/images/Favicon.png"
                  class="image">
                  </v-img>
                </div>
                <div class="mt-3">
                  <P class="font-title mb-0"  >
                      Welcome to
                  </P>
                  <p class="font-title mt-0 pt-0">
                    TaxPOD Vision {{ (platform=='dev')?"Dev":"" }}
                  </p>
                </div>
              </div>

              <div style="width:80%" class="mt-0 pt-0">

                <AError :api="api"></AError>
                <v-form @submit.prevent="fetch()">
                  <div class="">
                    <p class="font-subtitle mb-0" >
                        Email
                    </p>
                    <div class="">
                      <input style="height:40px" class="input-field px-3" placeholder="" ref="email" required :rules="rules" v-model="form.email" @keyup.enter="fetch"/>
                    </div>
                  </div>
  
                  <div class="">
                      <p class="font-subtitle mb-0">
                          Password
                      </p>
                      <input style="height:40px" :append-icon="showPassword?'mdi-eye':'mdi-eye-off'"
                      :type="showPassword?'text':'password'"  required class="input-field px-3"  placeholder="" ref="password" :rules="rules" v-model="form.password" @keyup.enter="fetch"/>
                  </div>
                </v-form>
              </div>
              <v-hover v-slot="{hover}">
                <div style="width:100%" class="d-flex justify-center mt-3 mb-6">
                  <v-btn flat  style="{font-size:60px;width:80%;border-radius:20px}" height="45px" type="submit" @click="fetch" :loading="api.isLoading"  class=" white--text elevation-0 btn-login " :color="(hover)?'primary':'#33BDF1'">
                          Login
                  </v-btn>
                </div>
              </v-hover>
            </v-card>
          </div>
        </div>

      </div>
      <!-- <v-row justify="center" align="center">
        <v-col cols="12" sm="6"  order="last">
          <v-row justify="center">
           

          </v-row>
        </v-col>
        <v-col cols="12" sm="6" order="first">

        </v-col>
      </v-row> -->
        <!-- <v-img
      src="https://s3-alpha-sig.figma.com/img/bbfc/1ee3/82aa86c80338e44b371ba1d4c7b84cd9?Expires=1685318400&Signature=mLAO4-npkHd7gdjMbnUjMTInSMP8uNq1Apdq3tk8I6iQjA5DTdnhCnnoWsYJL0OOt43Kl956MH-97NYA7oQc-zrKHkTeeoYp2UPJZUrbUg-YWn0v4E5sOuL~drJm4m01I4V7-lozcCgDD3MqmfAuFggbx-Jf~Rf-I00QpFsW~poMi2ubQPpG2iX2t-hx1xRJ~EcsOGbXhu4E4tdHpm0vIHItjjJDnI9M0PZLXwqjxz-eeMqtlfmf3WRQFTAdXroHq1LSI7GNRqG1kRzyGRd9ucolnsYuu9iqYHWITU3tfI5odmSn~lv6QjVQaHeWkm6u5DsnwO8UL3sn0cmX8~AXFw__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
      height="100%"
      width="100%"
      :contain="true">
        </v-img> -->
    </v-container>
</template>
<script>
 import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:[
      //
    ],
    data: () => ({
      platform : process.env.VUE_APP_ENV,
      valid:false,
      showEmailAlert:false,
      showpasswordAlert:false,
      rules:[
        cannotEmpty=>!!cannotEmpty||'This field is required',
      ],
      form:
      {
        email:null,
        password:null,
        fromVision:true,
      },
      showPassword:false,
      //BOC:[api]
      api: 
      {
        isLoading: false,
        isError: false,
        error: null,
        url: null,
      },
      // colors:
      // [
      //   'indigo',
      //   'warning',
      //   'pink',
        
      // ],
      // slide:
      // [
      //   'First Annoucement',
      //   'Second Announcement',
      //   'Third Annoucement',
      // ]
      //EOC
    }),
    created() {
      //BOC:[api]
      this.api.method = "post";
      this.api.url = process.env.VUE_APP_SERVER_API+"/login"
      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = (resp) => {
        if(resp.status == "success"){
          this.$store.commit("updateAuth", resp.data["api_token"]);
          this.$store.commit("updateRole",resp.data["role"]);
          this.$store.commit("updateEmail",resp.data["email"]);
          this.$store.commit("updateUserId",resp.data["id"]);
          this.$store.commit("updateFname",resp.data['fname']);
          this.$store.commit("updateLname",resp.data['lname']);
          this.$router.push({ name: 'PageHomeDashboard'});
        }
      };
    //EOC
    },
    mounted() {
      //
    },
    methods: {
      validateInput(){
        this.showEmailAlert=true;
        this.showpasswordAlert=true;
        if(this.form.email==null)
        {
            this.showEmailAlert=true;
            this.$refs.email.focus();
            return false;

        }
        if(this.form.password==null){
            this.showpasswordAlert=true;
            this.$refs.password.focus();
            return false;
        }
        // this.valid = true;
        return true;
      },
      fetch() {
        this.valid=this.validateInput();
        if(this.valid){
          this.api.params = this.$_.clone(this.form);
          this.$api.fetch(this.api);
        }
      },

    },
  }
</script>